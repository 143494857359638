const faqArray = [
    {
        key: "1", 
        id: "1", 
        question: "알파메일(α-Mail)이란 무엇인가요?", 
        answer: "알파메일(α-Mail) 은 AI를 이메일과 접목한 메일 플랫폼으로 스팸 메일 처리, 메일 형식 형성, 메일 분류 등 다방면에 AI를 활용할 수 있도록 한 스마트 메일 플랫폼이에요! 자동으로 메일을 생성하고 분류하는 편리한 α-Mail 을 사용해보세요!",
    },
    
    {
        key: "2", 
        id: "2", 
        question: "다른 메일과 연동이 되나요?", 
        answer: "구글의 gmail, 네이버의 네이버 메일 등 다른 메일 플랫폼에 들어있는 메일들도 한 번에 확인할 수 있어요!",
    },
    
    {
        key: "3", 
        id: "3", 
        question: "보안적으로 안전한가요?", 
        answer: "알파메일은 토큰 인증 방식을 이용하여 사용자에게 빠른 인증과 강력한 보안을 제공하고 있어요! 사용자의 정보는 토큰을 통해 안전하게 전달되어 중간에 악의적인 공격자로부터 침해받지 않아요! 이러한 인증은 구글 인증으로 3초면 충분해요! 뿐만 아니라 각 플랫폼 별로 개별 인증 과정을 지원하기 때문에, 내 메일을 보다 더 안전하게 관리할 수 있어요!",
    },
    
    {
        key: "4", 
        id: "4", 
        question: "메일 분류는 어떤 식으로 이루어지나요?", 
        answer: "빅데이터로 사전학습된 AI가 수신된 메일을 분석하여 메일을 분류해요! 스팸 메일 뿐만 아니라 자주 받는 메일, 중요한 메일 등을 지목해주면, 해당 메일들 또한 AI가 분류할 수 있어요!",
    },
    
    
]

export default faqArray; 

